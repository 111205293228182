






import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  metaInfo: {
    title: 'Jobs'
  },
})
export default class Jobs extends Vue {
}
